<template>
    <div class="create-bag-container">
        <div class="student-create-bag">
            <div class="path">
                <div class="item" v-for="(item, index) in navlist" :key="index">
                    <span class="text" @click="back">{{ item }}</span>
                    <span class="modify el-icon-arrow-right"></span>
                </div>
                <div class="item current">{{ current }}</div>
            </div>
            <div class="create-bag-title">{{ headLists }}</div>
            <div class="bag-input">
                <el-form :model="luckyBagData" :rules="rules" ref="ruleForm" style="margin-left: 7px;">
                    <el-form-item prop="bag_name">
                        <p class="bagTitle">福袋标题
                            <span class="tips">*</span>
                        </p>
                        <el-input v-model="luckyBagData.bag_name" class="el-input" placeholder="请输入福袋名称"></el-input>
                    </el-form-item>
                    <el-form-item prop="winning_number">
                        <p class="bagTitle">可中奖人
                            <span class="tips">*</span>
                        </p>
                        <el-input v-model="luckyBagData.winning_number" class="el-input" placeholder="请输入福袋个数"></el-input>
                        <span class="word1">人</span>
                    </el-form-item>
                    <el-form-item prop="minute">
                        <p class="bagTitle">倒计时
                            <span class="tips">*</span>
                        </p>
                        <el-input v-model="luckyBagData.minute" class="el-input-countdown" placeholder="≤60"></el-input>
                        <span class="word2">分</span>
                        <el-input v-model="luckyBagData.second" class="el-input-countdown" placeholder="≤60" style="margin-left: 11px;"></el-input>
                        <span class="word1">秒</span>
                    </el-form-item>
                    <el-form-item prop="in_type">
                        <p class="bagTitle">参与范围
                            <span class="tips">*</span>
                        </p>
                        <el-select 
                            v-model="luckyBagData.in_type"
                            placeholder="请选择参与范围" 
                            @visible-change="toggleClass"
                            :popper-append-to-body="false">
                            <el-option
                                v-for="item in rangeSelectList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="command_id">
                        <p class="bagTitle">参与口令
                            <span class="tips">*</span>
                        </p>
                        <el-select 
                            v-model="luckyBagData.command_id"
                            placeholder="请选择口令参与"
                            @visible-change="toggleClass"
                            :popper-append-to-body="false">
                            <el-option
                                v-for="item in wordSelectList"
                                :key="item.id"
                                :label="item.title"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <div class="form-button">
                        <el-button class="button" @click="resetForm()">取消</el-button>
                        <el-button class="button" type="primary" @click="submitForm('ruleForm')">{{ btn_word }}</el-button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
    import { getBagCommand, createOrEditLuckyBag, getLuckyBagDetail } from '@/utils/apis';
    
    export default {
        name: "StudentCreateBag",
        data() {
            let validateMin = (rule, value, callback) => {
                if (Number(this.luckyBagData.second) > 60 || Number(this.luckyBagData.minute) > 60) {
                    callback(new Error('输入时间数在0-60之间'));
                } else {
                    callback();
                }
            };
            return {
                list: ['直播营销', '直播促销', '创建福袋'],
                luckyBagData: {
                    lucky_bag_student_id: -1,
                    bag_name: '',
                    winning_number: '',
                    minute: '',
                    second: '',
                    countdown: '',
                    in_type: '',
                    command_id: '',
                },
                rules: {
                    bag_name: [{ required: true, message: '请输入福袋名称', trigger: 'blur' }],
                    winning_number: [{ required: true, message: '请输入可中奖人数', trigger: 'blur' }],
                    minute: [
                        // { required: true, message: '请输入倒计时分钟数', trigger: 'blur' },
                        // { type: "number", max: 60, message: "输入时间数在0-60之间", trigger: "blur" },
                        { type: "number", required: true, trigger: 'blur', validator: validateMin }
                    ],
                    in_type: [{ required: true, message: '请选择参与范围', trigger: 'change' }],
                    command_id: [{ required: true, message: '请选择口令参与', trigger: 'change' }]
                },
                rangeSelectList: [
                    {
                        value: 2,
                        label: '粉丝团福袋（仅限粉丝可参与）',
                    },
                    {
                        value: 1,
                        label: '全民福袋（所有观众可参与）',
                    }
                ],
                wordSelectList: [],
                headLists: '创建福袋',
                btn_word: '创建',
            }
        },
        computed: {
            navlist() {
                return this.list.slice(0, this.list.length - 1);
            },
            current() {
                return this.list.slice(-1)[0];
            },
        },
        methods: {
            back() {
                this.$router.back();
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.luckyBagData.countdown = String(this.luckyBagData.minute * 60 + Number(this.luckyBagData.second));
                        if (this.luckyBagData.lucky_bag_student_id == -1) {
                            delete this.luckyBagData.lucky_bag_student_id;
                            createOrEditLuckyBag(this.luckyBagData)
                            .then(
                                res => {
                                    if (res.code == 200) {
                                        this.$message.success(res.msg);
                                        this.$router.replace({
                                            path: '/liveMarketing/luckyBag',
                                            name: "LuckyBag",
                                        });
                                    } else {
                                        this.$message.error(res.msg);
                                    };
                                }
                            )
                        } else if(this.luckyBagData.lucky_bag_student_id != -1) {
                            createOrEditLuckyBag(this.luckyBagData)
                            .then(
                                res => {
                                    if (res.code == 200) {
                                        this.$message.success(res.msg);
                                        this.$router.replace({
                                            path: '/liveMarketing/luckyBag',
                                            name: "LuckyBag",
                                        });
                                    } else {
                                        this.$message.error(res.msg);
                                    };
                                }
                            )
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm() {
                this.$router.replace({
                    path: '/liveMarketing/luckyBag',
                    name: "LuckyBag",
                })
            },
            toggleClass() {
                if (document.querySelector('.popper__arrow') != null) {
                    document.querySelector('.popper__arrow').classList.replace('popper__arrow', 'new-popper__arrow');
                }
            }
        },
        mounted() {
            getBagCommand().then(
                res => {
                    this.wordSelectList = res.data
                }
            )
            if (this.$route.query.bag_id) {
                this.list = ['直播营销', '直播促销', '编辑福袋'];
                this.headLists = this.list[2], this.btn_word = '编辑';
                getLuckyBagDetail({lucky_bag_student_id: this.$route.query.bag_id}).then(
                    res => {
                        this.luckyBagData.lucky_bag_student_id = res.data.lucky_bag_student_id;
                        this.luckyBagData.bag_name = res.data.bag_name;
                        this.luckyBagData.winning_number = res.data.winning_number;
                        this.luckyBagData.in_type = res.data.in_type;
                        this.luckyBagData.command_id = res.data.command_id;
                        this.luckyBagData.countdown = res.data.countdown;
                        if (res.data.countdown < 60) {
                            this.luckyBagData.second = res.data.countdown % 60
                        } else {
                            this.luckyBagData.minute = parseInt(res.data.countdown / 60)
                            this.luckyBagData.second = res.data.countdown % 60
                        };
                    }
                )
            }
            
        }
    }
</script>

<style lang="scss" scoped>
    .word {
        display: inline-block;
        widows: 14px;
        height: 14px;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
    }
    .button {
        width: 77px;
        height: 40px;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 400;
    }
    .create-bag-container {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        .student-create-bag {
            .path {
                display: flex;
                margin-left: 6px;
                margin-top: 21px;
                color: #5C5C6F;
                .item {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .text {
                        transition: all 0.3s;
                        cursor: pointer;
                    }
                    .text:hover {
                        color: #2821fc;
                    }
                }
                .current {
                    color: #ABABBB;
                }
            }
            .create-bag-title {
                width: 64px;
                height: 16px;
                margin: 21px 0 14px 5px;
                font-size: 16px;
                font-weight: 500;
                color: #fff;
            }
            .bag-input {
                color: #fff;
                .tips {
                    color: red;
                    margin-left: 6px;
                }
                .bagTitle {
                    width: 72px;
                    height: 14px;
                    font-size: 14px;
                    font-weight: 400;
                    margin-left: 6px;
                }
                .el-input {
                    width: 380px;
                    height: 40px;
                    // margin-top: 10px;
                    margin: 10px 0 0 7px;
                    border-radius: 4px;
                }
                .word1 {
                    margin-left: 8px;
                    @extend .word
                }
                .word2 {
                    margin-left: 11px;
                    @extend .word
                }
                .el-input-countdown {
                    width: 172px;
                    height: 40px;
                }
                .form-button {
                    position: fixed;
                    bottom: 1.85%;
                    left: 68%;
                    width: 167px;
                    height: 40px;
                }
            }
            .new-popper__arrow {
                width: 0;
            }
        }
    }
    
    ::v-deep .el-form {
        .el-form-item {
            margin-bottom: 0;
        }
        .el-input__inner {
            color: #FFFFFF;
            background-color: #0C0E3F !important;
            border: 1px solid transparent;
            &::placeholder {
                color: #FFFFFF;
                opacity: 0.2;
                font-size: 14px;
                font-weight: 400;
            }
        }
        .el-input__inner:focus {
            border: 1px solid #4B45FF;
        }
        .el-form-item__error {
            margin-left: 7px;
        }
        .el-select {
            width: 380px;
            margin-top: 10px;
        }
        .el-icon-arrow-up:before {
            content: "\e790";
            color: #fff;
            opacity: 0.5;
            width: 12px;
            height: 7px;
        }
        .el-select-dropdown {
            height: auto;
            border: 1px solid transparent;
            background-color: #0C0E3F;
        }
        .el-scrollbar {
           height: auto;
        }
        .el-scrollbar__wrap {
            margin-bottom: 0 !important;
        }
        .el-select-dropdown__item {
            height: 36px;
            margin-top: 9px;
            font-size: 14px;
            font-weight: 400;
            color: #FFFFFF;
        }
        .el-select-dropdown__item:hover {
            background-color: #262963;
        }
        .el-select-dropdown__item.hover, .el-select-dropdown__item:hover {
            background-color: #262963;
        }
        .el-select-dropdown__item.selected {
            background-color: #262963;
        }
        .el-button {
            color: #fff;
            border: 1px solid #262963;
            background: #0C0E3F;
        }
        .el-button--primary {
            background: #4B45FF;
        }
        .el-button+.el-button {
            margin-left: 12px;
        }
    }
</style>